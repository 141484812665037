<header>
  <nav class="navbar navbar-expand-lg navbar-light bg-white">
    <div class="container-fluid">
      <a class="cursor-hand" (click)="onClickSidebar()" id="sidebarCollapse">
        <i class="fas fa-columns icon-color icon-size"></i>
      </a>
      <label class="navbar-brand icon-color ms-3">4HTV Subsystem</label>
      <a class="cursor-hand navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <!--span class="navbar-toggler-icon"></span-->
        <i class="fas fa-bars icon-color icon-size"></i>
      </a>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <!--ul class="navbar-nav me-auto mb-2 mb-lg-0"-->
          <!--li class="nav-item"-->
            <!--a id="sidebarCollapse" (click)="onSidenavToggle()" --><!--*ngIf="layoutService.sideBarVisible">-->
              <!--i class="fas fa-align-left icon-color"></i>
            </a-->
            <!--a class="nav-link cursor-hand" (click)="onClickSidebar()" id="sidebarCollapse">
              <i class="fas fa-bars icon-color"></i>
            </a-->
          <!--/li-->
          <!--li class="nav-item">
            <label class="nav-link navbar-brand icon-color">4HTV Subsystem</label>
          </li-->
        <!--/ul-->
        <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <label class="nav-link icon-color">山田　太郎 さん</label>
          </li>
          <li class="nav-item"><!-- *ngIf="layoutService.passwordChangeVisible">-->
            <!--a routerLink="/passwordchange" placement="bottom" ngbTooltip="パスワード変更">
              <i class="fas fa-user"></i>
            </a-->
            <a class="nav-link cursor-hand" (click)="onClickProfile()"><i class="fas fa-user-circle icon-color"></i> PROFILE</a>
          </li>
          <li class="nav-item"><!-- *ngIf="layoutService.logoutVisible">-->
            <!--a class="cursor-hand" (click)="logout()" placement="bottom" ngbTooltip="ログアウト">
              <i class="fas fa-sign-out-alt"></i>
            </a-->
            <a class="nav-link cursor-hand" (click)="onClickLogout()"><i class="fas fa-sign-out-alt icon-color"></i> LOGOUT</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <app-borders></app-borders>
</header>

