<nav id="sidebar" [ngClass]="{ active: toggleFlag }">
  <ul class="list-unstyled components">
    <ng-container *ngFor="let sidebarItem of sidebarItems">
      <ng-container *ngIf="sidebarItem.subItems.length > 0; else flatSidebar">
        <li>
          <a href="{{ sidebarItem.href }}" data-bs-toggle="collapse" attr.aria-expanded="{{ sidebarItem.expand }}" class="dropdown-toggle">{{ sidebarItem.caption }}</a>
          <ul class="collapse list-unstyled" [ngClass]="{ show: sidebarItem.expand }" id="{{ sidebarItem.id }}">
            <ng-container *ngFor="let subItem of sidebarItem.subItems">
              <li>
                <a routerLink="{{ subItem.link }}" (click)="onClickSidebarItem()" [queryParams]="subItem.query">{{ subItem.caption }}</a>
              </li>
            </ng-container>
          </ul>
        </li>
      </ng-container>
      <ng-template #flatSidebar>
        <li>
          <a routerLink="{{ sidebarItem.link }}" (click)="onClickSidebarItem()" [queryParams]="sidebarItem.query" matline>{{ sidebarItem.caption }}</a>
        </li>
      </ng-template>
    </ng-container>
  </ul>
</nav>

