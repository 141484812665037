<div class="modal-content">
  <div class="modal-header">
    <h5>自動投稿設定</h5>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!--div class="card-header">自動投稿設定　{{ title == '' ? '' : '【' + title + '】' }}</div-->

          <div class="card-body">
            <div class="row mb-4">
              <label class="col-sm-3 col-form-label" for="formFile">ファイル</label>
              <div class="col-sm-9">
                <input (change)="onChangeFile($event)" type="file" class="form-control" id="formFile" accept=".jpg,.png,.mov,.mp4">
                <input *ngIf="!dataSource.insertFlag" [(ngModel)]="dataSource.fileName" type="text" class="form-control" readonly>
              </div>
            </div>

            <div class="row mb-4">
              <label class="col-sm-3 col-form-label">投稿曜日</label>
              <div class="col-sm-9">
                <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
                  <ng-container *ngFor="let weekButtonItem of weekButtonItems">
                    <input [(ngModel)]="dataSource.postWeek" [value]="weekButtonItem.id" type="radio" name="radio-week" id="radio-week-{{ weekButtonItem.id }}" class="btn-check" autocomplete="off" checked>
                    <label class="btn {{ weekButtonItem.class }}" for="radio-week-{{ weekButtonItem.id }}">{{ weekButtonItem.caption }}</label>
                  </ng-container>
                </div>
              </div>
            </div>

            <div class="row mb-4">
              <label class="col-sm-3 col-form-label">投稿時間</label>
              <div class="col-sm-9">
                <app-timepicker [value]="dataSource.postTime" [timeType]="timeType"></app-timepicker>
              </div>
            </div>

            <div class="row mb-4">
              <label class="col-sm-3 col-form-label">コメント</label>
              <div class="col-sm-9">
                <app-textarea [value]="dataSource.comment" [maxLength]="maxLength"></app-textarea>
              </div>
            </div>

            <div class="row mb-4">
              <label class="col-sm-3 col-form-label">タグ</label>
              <div class="col-sm-9">
                <app-tag-input [value]="dataSource.tags"></app-tag-input>
              </div>
            </div>

            <!--div class="row">
              <div class="col gap-2 d-flex">
                <button type="button" class="btn btn-outline-secondary" (click)="onClickBack()">戻る</button>
              </div>
              <div class="col gap-2 d-flex justify-content-end">
                <button type="button" class="btn btn-primary">{{ dataSource.insertFlag ? '登録' : '更新' }}</button>
              </div>
            </div-->
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="onClickCancel()">キャンセル</button>
    <button type="button" class="btn btn-primary" (click)="onClickOk()">OK</button>
  </div>
</div>
