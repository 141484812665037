import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgxMaterialTimepickerModule } from "ngx-material-timepicker";
import { Ng2FlatpickrModule } from 'ng2-flatpickr';
import { TagInputModule } from 'ngx-chips';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { SimpleModalModule } from 'ngx-simple-modal';

import { BordersComponent } from './borders/borders.component';
import { ContentHeaderComponent } from './content-header/content-header.component';
import { TimepickerComponent } from './timepicker/timepicker.component';
import { DatepickerComponent } from './datepicker/datepicker.component';
import { TableComponent } from './table/table.component';
import { TagInputComponent } from './tag-input/tag-input.component';
import { ConfirmDialogComponent } from './dialog/confirm-dialog/confirm-dialog.component';
import { AutoPostSettingDialogComponent } from './dialog/auto-post-setting-dialog/auto-post-setting-dialog.component';
import { TextareaComponent } from './textarea/textarea.component';

@NgModule({
  declarations: [
    BordersComponent,
    ContentHeaderComponent,
    TimepickerComponent,
    DatepickerComponent,
    TagInputComponent,
    TableComponent,
    ConfirmDialogComponent,
    AutoPostSettingDialogComponent,
    TextareaComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgxMaterialTimepickerModule,
    Ng2FlatpickrModule,
    TagInputModule,
    NgxDatatableModule,
    SimpleModalModule
  ],
  exports: [
    FormsModule,
    BordersComponent,
    ContentHeaderComponent,
    TimepickerComponent,
    DatepickerComponent,
    TagInputComponent,
    TableComponent,
    ConfirmDialogComponent,
    AutoPostSettingDialogComponent,
    TextareaComponent
  ]
})
export class PartsModule { }
