import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss']
})
export class TextareaComponent implements OnInit {
  // 変数宣言
  @Input() value: string = "";
  @Input() maxLength: number = 0;

  /**
   * コンストラクタ
   */
  constructor() { }

  /**
   * 初期処理
   */
  ngOnInit(): void {
    console.log("textarea.component.ngOnInit()");
  }
}
