import { Component, OnInit } from '@angular/core';
import { SIDEBAR_ITEMS } from 'src/app/services/sidebar/sidebar-items';
import { Router } from '@angular/router';

@Component({
  selector: 'app-content-header',
  templateUrl: './content-header.component.html',
  styleUrls: ['./content-header.component.scss']
})
export class ContentHeaderComponent implements OnInit {
  // 変数宣言
  title: string = "";

  /**
   * コンストラクタ
   * @param { Router } rouer ルーター
   */
  constructor(public router: Router) {
  }

  /**
   * 初期処理
   */
  ngOnInit(): void {
    console.log("conent-header.component.ngOnInit()");
    for (let i = 0; i < SIDEBAR_ITEMS.length; i++) {
      let sidebarItem = SIDEBAR_ITEMS[i];
      if ("/" + sidebarItem.link == this.router.url) {
        this.title = sidebarItem.caption;
        break;
      } else {
        for (let j = 0; j < sidebarItem.subItems.length; j++) {
          let sidebarSubItem = sidebarItem.subItems[j];
          if ("/" + sidebarSubItem.link == this.router.url) {
            this.title = sidebarSubItem.caption;
            break;
          }
        }
      }
    }
  }
}
