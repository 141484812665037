import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-tag-input',
  templateUrl: './tag-input.component.html',
  styleUrls: ['./tag-input.component.scss']
})
export class TagInputComponent implements OnInit {
  // 変数宣言
  @Input() value: any[] = [];

  /**
   * コンストラクタ
   */
  constructor() {
  }

  /**
   * 初期処理
   */
  ngOnInit(): void {
    console.log("tag-input.component.ngOnInit()");
  }
}
