import { Component, OnInit } from '@angular/core';
import { Japanese } from 'flatpickr/dist/l10n/ja.js';
import { FlatpickrOptions } from 'ng2-flatpickr';

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss']
})
export class DatepickerComponent implements OnInit {
  // 変数宣言
  fpickerOptions: FlatpickrOptions = {};

  /**
   * コンストラクタ
   */
  constructor() {
  }

  /**
   * 初期処理
   */
  ngOnInit(): void {
    console.log("datepicker.component.ngOnInit()");
    this.setOption();
  }

  /**
   * オプション設定
   */
  setOption() {
    this.fpickerOptions = {
      locale: Japanese, // ロケールを日本
      enableTime: false, // 時刻選択 true: 有効 false: 無効
      enableSeconds: false, // 秒選択 true: 有効 false: 無効
      time_24hr: true, // 24時間表記を有効
      altInput: true, // 「代替表記用input要素を生成します。」??
      altFormat: 'Y-m-d', // 表示の書式
      allowInput: false, // 直接入力非許可
      altInputClass: 'form-control bg-white', // スタイルを適用
      clickOpens: true,
      position: 'auto'
    };
  }
}
