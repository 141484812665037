import { Component, OnInit, Input } from '@angular/core';
import { WeekButtonItem } from 'src/app/services/week-button/week-button-item';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { AutoPostSettingDialog } from './auto-post-setting-dialog';
import { WEEK_BUTTON_ITEMS } from 'src/app/services/week-button/week-button-items';
import { WeekButtonService } from 'src/app/services/week-button/week-button.service';

@Component({
  selector: 'app-auto-post-setting-dialog',
  templateUrl: './auto-post-setting-dialog.component.html',
  styleUrls: ['./auto-post-setting-dialog.component.scss']
})
export class AutoPostSettingDialogComponent extends SimpleModalComponent<AutoPostSettingDialog, boolean> implements AutoPostSettingDialog {
  // 変数宣言
  title: string = "";
  message: string = "";
  weekButtonItems: WeekButtonItem[] = [];
  //title: string = "";
  timeType: number = 0;
  maxLength: number = 140; // TODO!!ハッシュタグの文字数も考慮
  @Input() dataSource: any = {
    insertFlag: true,
    fileName: "",
    postWeek: 0,
    postTime: "",
    comment: "",
    tags: []
  };

  /**
   * コンストラクタ
   * @param { WeekButtonService } weekButtonService 投稿曜日ボタンサービス
   */
  constructor(public weekButtonService: WeekButtonService) {
    super();
  }

  /**
   * 初期処理
   */
  ngOnInit() {
    console.log("auto-post-setting-dialog.component.ngOnInit()");
    // 投稿曜日ボタン項目設定
    this.weekButtonItems = this.weekButtonService.getWeekButton(WEEK_BUTTON_ITEMS);
  }

  /**
   * ファイル選択変更イベントハンドラ
   * @param { File[] } files 選択ファイルリスト
   */
  onChangeFile(event: any) {
    console.log("auto-post-setting-dialog.component.onChangeFile()");
    if (event.target.files != null && event.target.files.length > 0) {
      let file = event.target.files[0];
      this.dataSource.fileName = file.name;
    }
  }

  /**
   * OKボタンクリックイベントハンドラ
   */
  onClickOk() {
    console.log("auto-post-setting-dialog.component.onClickOk()");
    this.result = true;
    this.close();
  }

  /**
   * キャンセルボタンクリックイベントハンドラ
   */
  onClickCancel() {
    console.log("auto-post-setting-dialog.component.onClickCancel()");
    this.result = false;
    this.close();
  }
}
