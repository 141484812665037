import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LayoutComponent } from './pages/layout/layout/layout.component';
import { LoginComponent } from './pages/login/login.component';

export const ROUTES: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      { path: '', redirectTo: '/auto-posts/reservations', pathMatch: 'full' },
      { path: 'auto-posts', loadChildren: () => import('./pages/auto-posts/auto-posts.module').then(m => m.AutoPostsModule) }
    ]
  },
  { path: 'login', component: LoginComponent },
];


@NgModule({
  imports: [
    RouterModule.forRoot(ROUTES, {
      scrollPositionRestoration: 'enabled'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
