import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-borders',
  templateUrl: './borders.component.html',
  styleUrls: ['./borders.component.scss']
})
export class BordersComponent implements OnInit {

  /**
   * コンストラクタ
   */
  constructor() { }

  /**
   * 初期処理
   */
  ngOnInit(): void {
    console.log("borders.component.ngOnInit()");
  }
}
