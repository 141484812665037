import { WeekButtonItem } from './week-button-item';

/**
 * 投稿曜日ボタン項目定数定義
 */
export const WEEK_BUTTON_ITEMS: WeekButtonItem[] = [
  { id: 1, caption: "月", class: "btn-outline-secondary" },
  { id: 2, caption: "火", class: "btn-outline-secondary" },
  { id: 3, caption: "水", class: "btn-outline-secondary" },
  { id: 4, caption: "木", class: "btn-outline-secondary" },
  { id: 5, caption: "金", class: "btn-outline-secondary" },
  { id: 6, caption: "土", class: "btn-outline-primary" },
  { id: 7, caption: "日", class: "btn-outline-danger" }
];