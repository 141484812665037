import { Component, OnInit, Input, HostListener, EventEmitter, Output } from '@angular/core';
import { SidebarItem } from 'src/app/services/sidebar/sidebar-item';
import { SIDEBAR_ITEMS } from 'src/app/services/sidebar/sidebar-items';
import { SidebarService } from 'src/app/services/sidebar/sidebar.service';


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  // 変数宣言
  @Input() toggleFlag: boolean = false; // サイドバー表示／非表示フラグ
  sidebarItems: SidebarItem[] = []; // サイドバー項目リスト
  displayWidth: number = 0; // ウィンドウサイズ
  @Output() sidebarEvent = new EventEmitter();

  /**
   * コンストラクタ
   * @param { SidebarService } sidebarService サイドバーサービス
   */
  constructor(public sidebarService: SidebarService) {
  }

  /**
   * 初期処理
   */
  ngOnInit(): void {
    console.log("sidebar.component.ngOnInit()");
    // ウィンドウサイズ初期値
    this.displayWidth = window.innerWidth;
    // サイドバー項目設定
    this.sidebarItems = this.sidebarService.getSidebar(SIDEBAR_ITEMS);
  }

  /**
   * サイドバー項目クリックイベントハンドラ
   */
  onClickSidebarItem() {
    console.log("sidebar.component.onClickSidebarItem()");
    if (this.displayWidth < 768) {
      // 親コンポーネントにサイドバークリックイベント発行
      this.sidebarEvent.emit();
    }
  }

  /**
   * ウィンドウサイズ変更イベントハンドラ
   * @param event イベント
   */
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.displayWidth = event.target.innerWidth;
  }
}
