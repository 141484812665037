import { SidebarItem } from './sidebar-item';

// 自動投稿
const SIDEBAR_ITEM_AUTO_POST: SidebarItem = {
  id: "auto-posts",
  caption: "自動投稿",
  link: "",
  href: "#auto-posts",
  expand: true,
  query: null,
  subItems: [
    {
      caption: "予約一覧",
      link: "auto-posts/reservations",
      query: null
    },
    {
      caption: "週間予約状況",
      link: "auto-posts/weeks",
      query: null
    }
  ]
};

/**
 * サイドバー項目定数定義
 */
export const SIDEBAR_ITEMS: SidebarItem[] = [
  SIDEBAR_ITEM_AUTO_POST
];