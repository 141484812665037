import { Component, DoCheck, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { TableColumn } from './table-column';
import { SelectionType } from '@swimlane/ngx-datatable';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements DoCheck {
  @Output() selectEvent = new EventEmitter();
  @Output() transitionEvent = new EventEmitter();
  @Output() deleteEvent = new EventEmitter();
  @Input() dataSource: any[] = [];
  @Input() tableColumn: TableColumn = <TableColumn>{};
  @Input() limit: number = 25;
  @Input() selected = [];
  @Input() isCheckBoxVisible = true;
  @Input() isAllRowsSelectVisible = true;
  @Input() scrollbarV = false;
  @Input() scrollbarH = false;
  public selectionType: SelectionType = SelectionType.checkbox;
  public footerHeight = 50;
  public gridData: any[] = [];

  customMessages = {
    // Message to show when array is presented
    // but contains no values
    emptyMessage: 'データなし',

    // Footer total message
    totalMessage: '合計',

    // Footer selected message
    selectedMessage: '選択中'
  };

  /**
   * コンストラクタ
   */
  constructor() {
  }

  /**
   * 初期処理
   */
  ngOnInit(): void {
    console.log("table.component.ngOnInit()");
  }

  ngDoCheck() {
    console.log("table.component.ngDoCheck()");
    this.footerHeight = this.scrollbarV ? 0 : 50;
    this.gridData = this.dataSource;
  }

  onSelect({ selected }: any) {
    console.log("table.component.onSelect()");
    this.selectEvent.emit(selected);
  }

  onClickTransition(value: any) {
    console.log("table.component.onClickTransition()");
    //value.gamenId = gamenId;
    this.transitionEvent.emit(value);
  }

  onDelete(value: any) {
    console.log("table.component.onDelete()");
    this.deleteEvent.emit(value);
  }

  onRowClass(row: any) {
    console.log("table.component.onRowClass()");
    return {
      "row-disabled" : row.disabled
    };
  }
}
