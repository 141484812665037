import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  // 変数宣言
  @Output() sidebarEvent = new EventEmitter();

  /**
   * コンストラクタ
   * @param { Router } router ルーター
   */
  constructor(public router: Router) {
  }

  /**
   * 初期処理
   */
  ngOnInit(): void {
    console.log("header.component.ngOnInit()");
  }

  /**
   * サイドバークリックイベントハンドラ
   */
  onClickSidebar() {
    console.log("header.component.onClickSidebar()");
    // 親コンポーネントにサイドバークリックイベント発行
    this.sidebarEvent.emit();
  }

  /**
   * プロフィールクリックイベントハンドラ
   */
  onClickProfile() {
    console.log("header.component.onClickProfile()");
  }

  /**
   * ログアウトクリックイベントハンドラ
   */
  onClickLogout() {
    console.log("header.component.onClickLogout()");
    //this.router.navigate(['/login']);
  }
}
