import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ROUTES } from 'src/app/app-routing.module';

import { PartsModule } from 'src/app/parts/parts.module';

import { HeaderComponent } from './layout/header/header.component';
import { FooterComponent } from './layout/footer/footer.component';
import { SidebarComponent } from './layout/sidebar/sidebar.component';
import { LayoutComponent } from './layout/layout.component';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    LayoutComponent
  ],
  imports: [
    RouterModule.forChild(ROUTES),
    CommonModule,
    PartsModule
  ],
  exports: [
    LayoutComponent
  ]
})
export class LayoutModule { }
