import { Component, Input, OnInit } from '@angular/core';

// 定数宣言
export const TIMEPICKER_OPTIONS: any = [
  { min: "00:00", max: "23:59" },
  { min: "00:00", max: "11:59" },
  { min: "12:00", max: "23:59" }
];

@Component({
  selector: 'app-timepicker',
  templateUrl: './timepicker.component.html',
  styleUrls: ['./timepicker.component.scss']
})
export class TimepickerComponent implements OnInit {
  // 変数宣言
  @Input() timeType: number = 0; // 時刻タイプフラグ
  @Input() value: string = ""; // 時刻値
  minValue: string = "";
  maxValue: string = "";

  /**
   * コンストラクタ
   */
  constructor() {
  }

  /**
   * 初期処理
   */
  ngOnInit(): void {
    console.log("timepicker.component.ngOnInit()");
    this.value = (this.value == "") ? TIMEPICKER_OPTIONS[this.timeType].min : this.value;
    this.minValue = TIMEPICKER_OPTIONS[this.timeType].min;
    this.maxValue = TIMEPICKER_OPTIONS[this.timeType].max;
  }

  /**
   * 時刻ピッカー変更イベントハンドラ
   */
  onChangeTimepicker() {
    console.log("timepicker.component.onChangeTimepicker()");
    console.log(this.value);
  }
}
