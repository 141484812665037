<div class="modal-content">
  <div class="modal-header">
    <h4>{{ title || "確認" }}</h4>
  </div>
  <div class="modal-body">
    <p>{{ message || "よろしいでしょうか" }}</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="onClickCancel()">キャンセル</button>
    <button type="button" class="btn btn-primary" (click)="onClickOk()">OK</button>
    </div>
</div>
