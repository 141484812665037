<ngx-datatable
  class="material"
  [class.height]="scrollbarV"
  [rows]="gridData"
  [headerHeight]="60"
  [rowHeight]="'auto'"
  [footerHeight]="footerHeight"
  [columnMode]="'force'"
  [selected]="selected"
  [selectionType]="selectionType"
  [selectAllRowsOnPage]="false"
  [limit]="limit"
  (select)="onSelect($event)"
  [messages]="customMessages"
  [scrollbarH]="scrollbarH"
  [scrollbarV]="scrollbarV"
  [swapColumns]="false"
  [reorderable]="false"
  [rowClass]="onRowClass">
  <ng-container *ngIf="isCheckBoxVisible">
    <ngx-datatable-column
      [width]="30"
      [sortable]="false"
      [canAutoResize]="false"
      [draggable]="false"
      [resizeable]="false">
      <ng-template
        ngx-datatable-header-template
        let-value="value"
        let-allRowsSelected="allRowsSelected"
        let-selectFn="selectFn">
        <ng-container *ngIf="isAllRowsSelectVisible">
          <input type="checkbox" [checked]="allRowsSelected" (change)="selectFn(!allRowsSelected)" />
        </ng-container>
      </ng-template>
      <ng-template
        ngx-datatable-cell-template
        let-value="value"
        let-isSelected="isSelected"
        let-onCheckboxChangeFn="onCheckboxChangeFn">
        <input type="checkbox" [checked]="isSelected" (change)="onCheckboxChangeFn($event)" />
      </ng-template>
    </ngx-datatable-column>
  </ng-container>
  <ng-container *ngFor="let column of tableColumn.columns">
    <ng-container *ngIf="column.linkFlg">
      <ngx-datatable-column
        [sortable]="false"
        [width]="70">
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          <button class="btn btn-primary btn-sm" href="javascript:void(0)" (click)="onClickTransition(row)" [hidden]="row.linkHidden">{{ column.column }}</button>
        </ng-template>
      </ngx-datatable-column>
    </ng-container>
    <ng-container *ngIf="column.deleteFlg">
      <ngx-datatable-column
        [sortable]="false"
        [width]="70">
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          <a href="javascript:void(0)" (click)="onDelete(row)" [hidden]="row.deleteHidden">{{ column.column }}</a>
        </ng-template>
      </ngx-datatable-column>
    </ng-container>
    <ng-container *ngIf="!column.linkFlg && !column.deleteFlg">
      <ngx-datatable-column [width]="column.width" [resizeable]="false" name="{{ column.caption }}" prop="{{ column.column }}"></ngx-datatable-column>
    </ng-container>
  </ng-container>
  <!-- <ng-container *ngIf="scrollbarV">
    <ngx-datatable-footer>
      <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize" let-selectedCount="selectedCount"
        let-curPage="curPage" let-offset="offset" let-isVisible="isVisible">
        <div class="container"></div>
      </ng-template>
    </ngx-datatable-footer>
  </ng-container> -->
</ngx-datatable>
