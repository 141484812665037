import { Component, OnInit } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { ConfirmDialog } from './confirm-dialog';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent extends SimpleModalComponent<ConfirmDialog, boolean> implements ConfirmDialog {
  // 変数宣言
  title: string = "";
  message: string = "";

  /**
   * コンストラクタ
   */
  constructor() {
    super();
  }

  /**
   * 初期処理
   */
  ngOnInit() {
    console.log("confirm-dialog.component.ngOnInit()");
  }

  /**
   * OKボタンクリックイベントハンドラ
   */
  onClickOk() {
    console.log("confirm-dialog.component.onClickOk()");
    this.result = true;
    this.close();
  }

  /**
   * キャンセルボタンクリックイベントハンドラ
   */
  onClickCancel() {
    console.log("confirm-dialog.component.onClickCancel()");
    this.result = false;
    this.close();
  }
}
