import { ConditionalExpr } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
  // 変数宣言
  toggleFlag: boolean = false;

  /**
   * コンストラクタ
   */
  constructor() {
  }

  /**
   * 初期処理
   */
  ngOnInit(): void {
    console.log("layout.component.ngOnInit()");
  }

  /**
   * 右クリックコンテキストメニューイベントハンドラ
   * @returns {Boolean} true: 右クリックコンテキストメニュー表示、false: 右クリックコンテキストメニュー非表示
   */
  onClickContextMenu() {
    console.log("layout.component.onClickContextMenu()");
    return false; // 右クリックコンテキストメニュー表示不可
  }

  /**
   * サイドバークリックイベント受信イベントハンドラ
   */
  onReceivedEventClickSidebar() {
    console.log("layout.component.onReceivedEventClickSidebar()");
    this.toggleFlag = !this.toggleFlag;
  }
}
