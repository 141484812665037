import { Injectable } from '@angular/core';
import { SidebarItem } from './sidebar-item';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {
  // 変数宣言
  private sidebarItems: SidebarItem[];

  /**
   * コンストラクタ
   */
  constructor() {
    this.sidebarItems = [];
  }

  /**
   * サイドバー項目取得
   * @param { SidebarItem[] } items サイドバー項目リスト
   * @returns { SidebarItem[] } サイドバー項目リスト
   */
  getSidebar(items: SidebarItem[]) {
    this.clear();
    this.set(items);
    return this.get();
  }

  /**
   * サイドバー項目初期化
   */
  clear() {
    this.sidebarItems.length = 0;
  }

  /**
   * サイドバー項目設定
   * @param { SidebarItem[] } items サイドバー項目リスト
   */
  set(items: SidebarItem[]) {
    items.forEach((item) => {
      this.sidebarItems.push(item);
    });
  }

  /**
   * サイドバー項目取得
   * @returns { SidebarItem[] } サイドバー項目リスト
   */
  get() {
    return this.sidebarItems;
  }
}
