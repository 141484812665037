import { Injectable } from '@angular/core';
import { WeekButtonItem } from './week-button-item';

@Injectable({
  providedIn: 'root'
})
export class WeekButtonService {
  // 変数宣言
  private weekButtonItems: WeekButtonItem[];

  /**
   * コンストラクタ
   */
  constructor() {
    this.weekButtonItems = [];
  }

  /**
   * 投稿曜日ボタン項目取得
   * @param { WeekButtonItem[] } items 投稿曜日ボタン項目リスト
   * @returns { WeekButtonItem[] } 投稿曜日ボタン項目リスト
   */
  getWeekButton(items: WeekButtonItem[]) {
    this.clear();
    this.set(items);
    return this.get();
  }

  /**
   * 投稿曜日ボタン項目初期化
   */
  clear() {
    this.weekButtonItems.length = 0;
  }

  /**
   * 投稿曜日ボタン項目設定
   * @param { WeekButtonItem[] } items 投稿曜日ボタン項目リスト
   */
  set(items: WeekButtonItem[]) {
    items.forEach((item) => {
      this.weekButtonItems.push(item);
    });
  }

  /**
   * 投稿曜日ボタン項目取得
   * @returns { WeekButtonItem[] } 投稿曜日ボタン項目リスト
   */
  get() {
    return this.weekButtonItems;
  }
}
