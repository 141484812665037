<div class="wrapper" (contextmenu)="onClickContextMenu()">
  <!--app-header (sidenavToggle)="sidenavToggle()"></app-header--> <!--*ngIf="layoutService.headerVisible"></app-header>-->
  <app-header (sidebarEvent)="onReceivedEventClickSidebar()"></app-header>
  <div class="sub-wrapper">
    <!--app-sidebar [toggle]='toggle'></app-sidebar--><!-- *ngIf="layoutService.sideBarVisible"></app-sidebar>-->
    <app-sidebar (sidebarEvent)="onReceivedEventClickSidebar()" [toggleFlag]="toggleFlag"></app-sidebar>
    <!-- Page Content  -->
    <!--div class="content-wrapper" [class.content-open]="toggle || !layoutService.sideBarVisible" [class.content]="!toggle || layoutService.sideBarVisible"></div>-->
    <div class="content-wrapper" [class.content-open]="toggleFlag" [class.content]="!toggleFlag || true">
      <router-outlet></router-outlet>
    </div>
  </div>
  <!--app-footer *ngIf="layoutService.footerVisible"></app-footer>-->
  <app-footer></app-footer>
</div>
